import { default as _91productKey_93tfAXmy1y2HMeta } from "/opt/buildhome/repo/frontend/pages/[productKey].vue?macro=true";
import { default as cartCaLxHiwOLhMeta } from "/opt/buildhome/repo/frontend/pages/cart.vue?macro=true";
import { default as index5eOmP0fFyLMeta } from "/opt/buildhome/repo/frontend/pages/index.vue?macro=true";
import { default as indexLegacyRkXgPo1s4WMeta } from "/opt/buildhome/repo/frontend/pages/indexLegacy.vue?macro=true";
import { default as tableMS3WillPePMeta } from "/opt/buildhome/repo/frontend/pages/table.vue?macro=true";
export default [
  {
    name: "productKey",
    path: "/:productKey()",
    component: () => import("/opt/buildhome/repo/frontend/pages/[productKey].vue")
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/opt/buildhome/repo/frontend/pages/cart.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/frontend/pages/index.vue")
  },
  {
    name: "indexLegacy",
    path: "/indexLegacy",
    component: () => import("/opt/buildhome/repo/frontend/pages/indexLegacy.vue")
  },
  {
    name: "table",
    path: "/table",
    component: () => import("/opt/buildhome/repo/frontend/pages/table.vue")
  }
]